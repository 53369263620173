<script>
	import { onMount } from 'svelte';
	
	let tally = 0;
	let CART = [];
	const bottom = document.getElementById('bottom_tally') || false;

	function readCart() {
		CART = localStorage.cart ? JSON.parse(localStorage.cart) || [] : [];
	}

	function updateTally() {
		readCart();
		if (!CART || !CART.length) { // if no cart, tally should be 0
			tally = 0;
		} else { // reduce the cart to calculate the tally
			tally = CART.reduce(function (accumulator, obj) {
				// PROBLEM!!!!!!!!
				return accumulator + obj.quantity;
			}, 0);
		}
	}

	function checkout() {
		window.location = `/checkout`;
	}

	window.addEventListener("cartUpdate", function(event) {
		updateTally();
	});

	onMount(async () => {
		updateTally();
	});
	
</script>







{#if tally}<span on:click={checkout}>You have {tally} {tally>1 ? `items` : `item`} in your cart</span>{/if}