<script>
	import { onMount } from 'svelte';
	
	let CART = [];

	let itemId = parseInt($$props.itemId);
	let itemBuyable = parseInt($$props.itemBuyable);
	let itemMax = parseInt($$props.itemMax);

	$:  ITEM = CART && CART.length ? CART.find(obj => obj.item == itemId) || false : false;
	$:  QUANTITY = ITEM ? ITEM.quantity : 0;

	
	function readCart() {
		CART = localStorage.cart ? JSON.parse(localStorage.cart) || [] : [];
	}

	function writeCart() {
		localStorage.cart = JSON.stringify( CART.filter(Boolean) );
		window.dispatchEvent(new CustomEvent("cartUpdate"));
	}

	function more() {
		readCart();
		if (itemMax && itemMax==QUANTITY) { // maximum threshold
			alert(`Sorry, maximum of ${itemMax} per customer`);
			return;
		}
		if (!ITEM) { // if there's no item yet, add to cart, 
			CART.push({ item: itemId, quantity: 1 });
		} else { // otherwise, increment the quantity
			CART = CART.map(obj => {
				if (obj.item == itemId)	obj.quantity = QUANTITY + 1;
				return obj;
			}).filter(Boolean);
		}
		writeCart();
	}

	function less() {
		readCart();
		if (!QUANTITY) return;
		if (QUANTITY==1) { // if there's only one, remove this object from cart
			CART = CART.map(obj => {
				if (obj.item != itemId)	return obj;
			}).filter(Boolean);
		} else { // otherwise, just decrement it
			CART = CART.map(obj => {
				if (obj.item == itemId)	obj.quantity = QUANTITY - 1;
				return obj;
			}).filter(Boolean);
		}
		writeCart();
	}

	function checkout() {
		window.location = `/checkout`;
	}

	onMount(async () => {
		readCart();
	});
	
</script>




{#if itemBuyable && !QUANTITY}
	<div class="cart_ctrl">
		<button on:click={more} class="cart_ctrl__button__blue">add to cart</button>
	</div>
{:else if itemBuyable && QUANTITY}
	<div class="cart_ctrl">
		<button on:click={more} class="cart_ctrl__button__green">+ more <span>{QUANTITY}</span></button>
		<button on:click={less}>less</button>
		<button on:click={checkout}>checkout</button>
	</div>
{:else if !itemBuyable}
	<div class="unavailable">
		<span>out of stock</span>
	</div>
{/if}




<style>
	.unavailable {
		font: inherit;
		color: var(--unavailable);
		margin: .2em .5em .2em 0;
		text-transform: uppercase;
		letter-spacing: .025em;
		font-weight: 600;
		font-size: .7rem;
		text-decoration: none;
	}
</style>